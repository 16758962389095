import React from 'react';
import { Header } from '../components/Header';

function About() {
return (
    <div className="App">
        <div className='main-content'>
            <Header pageTitle="404: Not Found"/>
        </div>
    </div>
    );
}

export default About;